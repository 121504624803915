import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy } from "react";

import Loader from "../component/admin/loader/Loader";
import Loadable from "../component/admin/Loadable";
import PrivateRoutes from "./PrivateRoutes";
import PageNotFound from "../container/admin/pages/PageNotFound";

// Auth imporrts
const Login = Loadable(
  lazy(() => import("../container/admin/auth/login/Login"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../container/admin/auth/forgotPassword/ForgotPassword"))
);
const OtpVerify = Loadable(
  lazy(() => import("../container/admin/auth/otpVerify/OtpVerify"))
);
const ResetPassword = Loadable(
  lazy(() => import("../container/admin/auth/resetPassword/ResetPassword"))
);

// Singup imports
const Signup = Loadable(
  lazy(() => import("../container/admin/auth/signup/Signup"))
);
const BusinessImage = Loadable(
  lazy(() => import("../container/admin/pages/businessImages/BusinessImage"))
);
const Drop = Loadable(lazy(() => import("../container/admin/pages/drop/Drop")));
const PayMent = Loadable(
  lazy(() => import("../container/admin/pages/payment/Payment"))
);

// Dashboard Imports
const Dashboard = Loadable(
  lazy(() => import("../container/admin/pages/Dashboard"))
);
const Home = Loadable(lazy(() => import("../container/admin/pages/home/Home")));

const Notifications = Loadable(
  lazy(() => import("../container/admin/pages/notifications/Notifications"))
);

const PostofferManagement = Loadable(
  lazy(() => import("../container/admin/pages/postOfferManagement/PostOfferManagement"))
);

const Profile = Loadable(
  lazy(() => import("../container/admin/pages/profile/Profile"))
);
const BookingsAndSeat = Loadable(
  lazy(() => import("../container/admin/pages/bookingsAndSeat/Index"))
);
const Offer = Loadable(
  lazy(() => import("../container/admin/pages/offer/Offer"))
);
const OfferRequest = Loadable(
  lazy(() => import("../container/admin/pages/offerRequest/OfferRequest"))
);
const ViewOfferRequest = Loadable(
  lazy(() => import("../container/admin/pages/offerRequest/view/ListOfferRequest"))
);

const DownloadInvoice = Loadable(
  lazy(() => import("../container/admin/pages/profile/transactionDetails/invoice/DownloadInvoice"))
  )


export default function AllRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Auth routes */}
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="otp-verify" element={<OtpVerify />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="signup" element={<Signup />} />
          <Route path="business-logo" element={<Drop />} />
          <Route path="business-image" element={<BusinessImage />} />
          <Route path="payment" element={<PayMent />} />
          <Route path="download-invoice" element={<DownloadInvoice />} />

          {/* Pages routes */}
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Dashboard />}>
              <Route path="" element={<Home />} />
              <Route
                path="notifications"
                element={<Notifications />}
              />
              <Route path="create-offer" element={<Offer/>} />
             
              <Route path="post-offer-management" element={<PostofferManagement />} />
             
              <Route
                path="booking-seat-creation"
                element={<BookingsAndSeat />}
              />
              <Route path="profile" element={<Profile />} />
              <Route path="loader" element={<Loader/>} />

              <Route path="offer-request" element={<OfferRequest/>} />
              <Route path="view-offer-request" element={<ViewOfferRequest/>} />

            </Route>
          </Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
