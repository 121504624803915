import jwtDecode from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";
import dataService from "../config/dataservice";
import Index from "../component/componentIndex";

const PrivateRoutes = () => {
  const isValidToken = (token: string | null) => {
    if (!token) return false;

    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken?.exp > currentTime) {
      // dataService.defaults.headers.common.auth = token;
      return true;
    } else {
      localStorage.removeItem("BusinessUser");
      return false;
    }
  };

  const token = Index.useAppSelector((state)=>state?.businessReducer?.token)

  return isValidToken(token) ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
