import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  businessUserDetails: {},
  registeredBusinessId: "",
  registeredBusinessPaymentId:"",
  notificationCount : 0
};

const businessSlice = createSlice({
  name: "business",
  initialState: initialState,
  reducers: {
    setRegisteredBusinessId: (state, action) => {
      state.registeredBusinessId = action.payload;
    },
    setRegisteredBusinessPaymentId: (state, action) => {
      state.registeredBusinessPaymentId = action.payload;
    },
    login: (state, action) => {
      state.token = action.payload.token;
      state.businessUserDetails = action.payload;
    },
    setBusinessDetailsAfterUpdate: (state, action) => {
      state.businessUserDetails = action.payload;
    },
    setNotificationCount:(state,action)=>{
      state.notificationCount = action.payload;
    },
    logout: () => {
      return initialState;
    },
  },
});
export const {
  setRegisteredBusinessId,
  setRegisteredBusinessPaymentId,
  login,
  setBusinessDetailsAfterUpdate,
  logout,
  setNotificationCount
} = businessSlice.actions;

export default businessSlice.reducer;
