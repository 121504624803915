import Index from "../../componentIndex";
import { Link, useLocation } from "react-router-dom";
import { logout, setNotificationCount } from "../../../redux/business/businessSlice";
import dataService from "../../../config/dataservice";
import { api } from "../../../config/api";
import { useEffect, useState } from "react";

function Sidebar() {
  const dispatch = Index.useAppDispatch();
  const navigate = Index.useNavigate();
  const location = useLocation();

  // Notification count
  const notificationCount = Index.useAppSelector((state)=>state.businessReducer.notificationCount);

  const getAllBookingsNotoifications = () => {
    dataService
      .get(api.business.getAllBookingsNotifications)
      .then((res) => {
        dispatch(setNotificationCount(res.data.data.length))
      })
      .catch((e) => {});
  };

  useEffect(()=>{
    getAllBookingsNotoifications();
  },[])
  return (
    <>
      <Index.Box className="sidebar-content">
        <Index.Box>
          <Index.Box className="sidebar-logo">
            <img src={Index.Svg.Logo} alt="" className="d-flex side-logo" />
          </Index.Box>
          <Index.Box className="sidebar-inner-content">
            <Index.List className="sidebar-ul">
              <Link to="/">
                <Index.ListItem
                  className={location.pathname === "/" ? "active" : ""}
                >
                  <Index.Box className="dash-inner-li ">
                    <img src={Index.Svg.Dash} alt="" className="dash-img" />
                    <Index.Box className="side-title">Dashboard </Index.Box>
                  </Index.Box>
                </Index.ListItem>
              </Link>
              <Link to="/booking-seat-creation">
                <Index.ListItem
                  className={
                    location.pathname === "/booking-seat-creation"
                      ? "active"
                      : ""
                  }
                >
                  <Index.Box className="dash-inner-li">
                    <img src={Index.Svg.Booking} alt="" className="dash-img" />
                    <Index.Box className="side-title">
                      Booking / Seating
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
              </Link>
              <Link to="/create-offer">
                <Index.ListItem
                  className={
                    location.pathname === "/create-offer" ? "active" : ""
                  }
                >
                  <Index.Box className="dash-inner-li">
                    <img src={Index.Svg.Offer} alt="" className="dash-img" />
                    <Index.Box className="side-title">Create Offers </Index.Box>
                  </Index.Box>
                </Index.ListItem>
              </Link>
              <Link to="/post-offer-management">
                <Index.ListItem
                  className={
                    location.pathname === "/post-offer-management"
                      ? "active"
                      : ""
                  }
                >
                  <Index.Box className="dash-inner-li">
                    <img src={Index.Svg.Offer} alt="" className="dash-img" />
                    <Index.Box className="side-title">
                      Post Offers Management{" "}
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
              </Link>
              <Link to="/offer-request">
                <Index.ListItem
                  className={
                    location.pathname === "/offer-request" ? "active" : ""
                  }
                >
                  <Index.Box className="dash-inner-li">
                    <img src={Index.Svg.Offer} alt="" className="dash-img" />
                    <Index.Box className="side-title">
                      Requested offers
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
              </Link>
              <Link to="/profile">
                <Index.ListItem
                  className={location.pathname === "/profile" ? "active" : ""}
                >
                  <Index.Box className="dash-inner-li">
                    <img src={Index.Svg.User} alt="" className="dash-img" />
                    <Index.Box className="side-title">My Profile</Index.Box>
                  </Index.Box>
                </Index.ListItem>
              </Link>
              <Link to="/notifications">
                <Index.ListItem
                  className={
                    location.pathname === "/notifications" ? "active" : ""
                  }
                >
                  <Index.Box className="dash-inner-li">
                    <img
                      src={Index.Svg.notification}
                      alt=""
                      className="dash-img"
                    />
                    <Index.Box className="side-title">Notifications</Index.Box>
                  </Index.Box>
                  {notificationCount ? (
                    <Index.Box className="noti-round">
                      {notificationCount}
                    </Index.Box>
                  ) : (
                    ""
                  )}
                </Index.ListItem>
              </Link>

              <Index.ListItem
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(logout());
                  navigate("/");
                }}
              >
                <Index.Box className="dash-inner-li">
                  <img src={Index.Png.Logout} alt="" className="dash-img" />
                  <Index.Box className="side-title">Logout</Index.Box>
                </Index.Box>
              </Index.ListItem>
            </Index.List>
          </Index.Box>
          <Index.Box className="support">Contact Zeebra Support</Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Sidebar;
