import Png from "../assets/Png";
import Jpg from "../assets/Jpg";
import Svg from "../assets/Svg";
import Sidebar from "./admin/defaultLayout/Sidebar";
import Header from "./admin/defaultLayout/ResponsiveHeader";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
import moment from "moment";
import {
  Box,
  Typography,
  InputBase,
  InputLabel,
  FormControl,
  Stack,
  TextField,
  InputAdornment,
  FormHelperText,
  OutlinedInput,
  IconButton,
  Link,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Tab,
  Tabs,

  // nisha
  Modal,
  Paper,
  Grid,
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Autocomplete
} from "@mui/material";

import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { CONSTANT } from "../common/constant";
import Loader from "./admin/loader/Loader";
import ApiLoader from "./admin/apiLoader/ApiLoader";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

//Claender related components

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "../redux/controller";
import SearchIcon from "@mui/icons-material/Search";

import { successToast, errorToast } from "../common/functions";

export default {
  Jpg,
  Png,
  Svg,
  Box,
  Typography,
  InputBase,
  InputLabel,
  FormControl,
  Stack,
  TextField,
  InputAdornment,
  FormHelperText,
  OutlinedInput,
  IconButton,
  Visibility,
  VisibilityOff,
  Link,
  Button,
  Sidebar,
  Header,
  List,
  ListItem,
  ListItemText,
  Select,
  ListItemAvatar,
  Avatar,
  ImageIcon,
  WorkIcon,
  BeachAccessIcon,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  // nisha
  Modal,
  Paper,
  Grid,
  Checkbox,
  MenuItem,
  TextareaAutosize,
  toast,
  CircularProgress,
  useNavigate,
  useLocation,
  moment,
  CONSTANT,
  Loader,
  ApiLoader,
  AccessTimeIcon,
  CalendarMonthIcon,
  dayjs,
  MobileTimePicker,
  MobileDatePicker,
  LocalizationProvider,
  AdapterDayjs,
  DemoItem,
  DemoContainer,
  useAppSelector,
  useAppDispatch,
  Pagination,
  SearchIcon,
  useParams,
  errorToast,
  successToast,
  Autocomplete
};
