import Logo from "./images/svg/logo.svg";
import Backarrow from "./images/svg/backarrow.svg";
import Dash from "./images/svg/dash.svg";
import Booking from "./images/svg/booking-seating.svg";
import Offer from "./images/svg/offer.svg";
import User from "./images/svg/user.svg";
import notification from "./images/svg/notification.svg";
import dashcheck from "./images/svg/card-check.svg";
import dashcircle from "./images/svg/dash-circle.svg";
import offerrequest from "./images/svg/offer-request-vector.svg";
import cross from "./images/svg/cross.svg";
import seatcreation from "./images/svg/seatcreation.svg";
import visalogo from "./images/svg/visalogo.svg";
import cloudupload from "./images/svg/cloudupload.svg";
import Delete from "./images/svg/delete.svg";
import Mastercard from "./images/svg/mastercard.svg";
import Seat from "./images/svg/seat.svg";
import minuscircle from "./images/svg/minus-circle.svg";
import pluscircle from "./images/svg/plus-circle.svg";
import metrofire from "./images/svg/metrofire.svg";
import LogoPdf from './images/svg/LogoPdf.svg'

const Svg = {
  Logo,
  Backarrow,
  Dash,
  Booking,
  Offer,
  User,
  notification,
  dashcheck,
  dashcircle,
  offerrequest,
  cross,
  seatcreation,
  visalogo,
  cloudupload,
  Delete,
  Mastercard,
  Seat,
  minuscircle,
  pluscircle,
  metrofire,
  LogoPdf
};

export default Svg;
